import { NavLink } from "react-router-dom";
import useWindowDimensions from "../functions/WindowDimensions";

export function FooterNavigationItems({ classes }) {
  const { isMobile, isTablet, isDesktop } = useWindowDimensions();

  const baseClasses = "text-white   w-100";
  const mobileClasses = "";
  const tabletClasses = "";
  const desktopClasses = " mx-s";

  const footerListClasses =
    baseClasses +
    (isMobile ? mobileClasses : isTablet ? tabletClasses : desktopClasses);

  return (
    <ul className={classes}>
      <li className={footerListClasses}>
        <NavLink
          to={"/upload-cv"}
          className="text-white h4 w-100 transition-all py-xs"
        >
          Upload CV
        </NavLink>
      </li>
      <li className={footerListClasses}>
        <NavLink
          to={"/over-ons"}
          className="text-white h4 w-100 transition-all py-xs"
        >
          Over ons
        </NavLink>
      </li>
      <li className={footerListClasses}>
        <NavLink
          to={"/jobs"}
          className="text-white h4 w-100 transition-all py-xs"
        >
          Jobs
        </NavLink>
      </li>
      <li className={footerListClasses}>
        <NavLink
          to={"/hoe-wij-werken"}
          className="text-white h4 w-100 transition-all py-xs"
        >
          Hoe wij werken
        </NavLink>
      </li>
      <li className={footerListClasses}>
        <NavLink
          to={"/contact"}
          className="text-white h4 w-100 transition-all py-xs"
        >
          Contact
        </NavLink>
      </li>
    </ul>
  );
}
