import { NavigationItems } from "../atoms/NavigationItems";
import { UploadLink } from "../atoms/UploadLink";
import "animate.css";

export function NavigationDesktop() {
  return (
    <div className="navbar d-flex flex-row justify-sb max-w-1000 vw-80 min-w-750 align-c">
      <UploadLink className="h4 w-100 max-w-200" />
      <NavigationItems classes={`w-100 justify-e d-flex align-c justify-sb`} />
    </div>
  );
}
