import {
  faPhone,
  faEnvelope,
  faLocationDot,
  faArrowUpRightFromSquare,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import Popup from "reactjs-popup";
import { ReactComponent as Rocket } from "../images/rocket.svg";

export function ContactItems({ classes }) {
  const [open, setOpen] = useState(false);
  const [clickCount, setClickCount] = useState(0);

  const handleClick = () => {
    navigator.clipboard.writeText("Luchthavenlaan 27, 1800 Vilvoorde Belgium");
    setClickCount(clickCount + 1);
    setOpen(true);
    setTimeout(() => {
      setOpen(false);
    }, 1000);
  };

  return (
    <div className={classes}>
      <a
        href="tel:+3223100000"
        className="w-100 text-white py-xs transition-all"
      >
        <FontAwesomeIcon icon={faPhone} />
        <span className="pl-xs">+32 2 310 00 00</span>
      </a>
      <a
        href="mailto:info@rp-one.eu"
        className="w-100 text-white py-xs transition-all"
      >
        <FontAwesomeIcon icon={faEnvelope} />
        <span className="pl-xs">info@rp-one.eu</span>
      </a>

      <Popup
        trigger={
          <div className="w-100 text-white py-xs d-flex justify-s align-c link transition-all">
            <FontAwesomeIcon width={16} icon={faLocationDot} />
            <address className="pl-xs">
              Luchthavenlaan 27,
              <br />
              1800 Vilvoorde
              <br />
              Belgium
            </address>
          </div>
        }
        open={open}
        closeOnDocumentClick={false}
        position="top center"
        onOpen={handleClick}
      >
        {(close) => (
          <div className="text-a-c">
            {clickCount !== 1 ? (
              clickCount >= 10 ? (
                <div className="d-flex flex-col justify-c align-c text-a-c bg-primary text-white">
                  Blast Off!{" "}
                  <Rocket style={{ width: "100px", height: "100px" }} />
                </div>
              ) : (
                `Copied to clipboard! x${clickCount}`
              )
            ) : (
              "Copied to clipboard!"
            )}
          </div>
        )}
      </Popup>

      <a
        href="https://goo.gl/maps/eMvs3eWuC17bH5mi8"
        target="_blank"
        rel="noreferrer"
        className="w-100 text-white py-xs transition-all"
      >
        <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
        <span className="pl-xs">Toon op Google Maps</span>
      </a>
    </div>
  );
}
