import { Divider } from "./Divider";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLocationDot,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

export function JobCard({ title, place, id, parent }) {
  return (
    <Link
      className="bg-white shadow border-radius-m px-s py-s mb-s w-100 max-w-300 d-block-inline"
      to={`/jobs/${parent}/${id}`}
    >
      <div className="d-flex align-c gap-s justify-sb">
        <div className="card-text">
          <h4>{title}</h4>
          <p className="my-xs">
            <FontAwesomeIcon icon={faLocationDot} className="mr-s" />
            {place}
          </p>
        </div>
        <FontAwesomeIcon
          icon={faChevronRight}
          className="card-chevron transition-all h2"
        />
      </div>
      <Divider size="var(--divider-length)" />
    </Link>
  );
}
