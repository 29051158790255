import { Hero } from "../components/Hero";
import { ButtonCta } from "../atoms/Button-cta";
import { Section } from "../components/Section";
import { Button } from "../atoms/Button";
import { useNavigate } from "react-router-dom";
import { Page } from "../components/Page";
import image from "../images/home-kandidaten.jpg";

export function HomeRoute() {
  let navigate = useNavigate();

  return (
    <Page>
      <Hero title={"Consultancy Done Right!"}>
        <ButtonCta onClick={() => navigate("/upload-cv")} text="Join us" />
      </Hero>
      <Section image={image}>
        <h1 className="mb-s">Wij helpen jou om je carrière te lanceren!</h1>
        <p>
          Ben jij pas afgestudeerd? Of ben je op zoek naar een nieuwe wending in
          je carrière? Dan ben je bij ons aan het juiste adres! RP One helpt jou
          om je carrière te (her)lanceren. Met onze jarenlange ervaring en
          specialisatie in IT development en onze grondige marktkennis gaan we
          meteen aan de slag voor je. We luisteren naar wat jouw ervaringen,
          talenten en wensen zijn om vervolgens een passend project bij een van
          onze partners te vinden.
        </p>
        <p>
          Ben je klaar voor een nieuw avontuur? Neem dan snel een kijkje in onze
          openstaande jobs.
        </p>

        <Button onClick={() => navigate("/jobs")} text={"Bekijk onze jobs"} />
      </Section>
    </Page>
  );
}
