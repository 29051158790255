import { Hero } from "../components/Hero";
import { useParams } from "react-router-dom";
import { jobs } from "../data";
import { UploadForm } from "../components/UploadForm";
import { Divider } from "../atoms/Divider";
import useWindowDimensions from "../functions/WindowDimensions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { Page } from "../components/Page";

export function JobsDetailsRoute() {
  const { jobID, openingID } = useParams();
  const job = jobs.find((job) => job.id === jobID);
  const opening = job.openings.find((opening) => opening.id === openingID);

  const { isMobile, isTablet, isDesktop } = useWindowDimensions();

  const sectionBaseClass = "";
  const sectionMobileClass = " d-flex flex-col justify-c align-c";
  const sectionTabletClass =
    " d-flex flex-col justify-c align-c my-m mx-s gap-s max-w-1000";
  const sectionDesktopClass =
    " d-flex flex-row flex-wrap justify-c my-m mx-auto gap-s max-w-1350";
  const sectionClasses =
    sectionBaseClass +
    (isMobile
      ? sectionMobileClass
      : isTablet
      ? sectionTabletClass
      : sectionDesktopClass);

  return (
    <Page>
      <Hero title={opening.title} />
      <section className={sectionClasses}>
        <article className={`mx-s max-w-700`}>
          <h1>Jobinhoud</h1>
          <Divider size={"250px"} />
          {opening.jobDescription.map((desc, index) => (
            <p key={index}>{desc}</p>
          ))}
          {opening.jobRequirements && (
            <>
              <h1>Skills</h1>
              <Divider size={"250px"} />
              <ul>
                {opening.jobRequirements.map((requirement, index) => (
                  <li key={index} className="d-flex">
                    <FontAwesomeIcon
                      icon={faPlus}
                      className="text-accent mr-xs pos-r h3"
                      style={{ top: "2px" }}
                    />
                    <p className="mt-0">{requirement}</p>
                  </li>
                ))}
              </ul>
            </>
          )}
          {opening.jobBenefits && (
            <>
              <h1>Aanbod</h1>
              <Divider size={"250px"} />
              <ul>
                {opening.jobBenefits.map((benefit, index) => (
                  <li key={index} className="d-flex">
                    <FontAwesomeIcon
                      icon={faPlus}
                      className="text-accent mr-xs pos-r h3"
                      style={{ top: "2px" }}
                    />
                    <p className="mt-0">{benefit}</p>
                  </li>
                ))}
              </ul>
            </>
          )}
        </article>
        <div className="max-w-500">
          <UploadForm />
        </div>
      </section>
    </Page>
  );
}
