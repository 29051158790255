export function VoorWerkgevers() {
  return (
    <div>
      <h1>Voor werkgevers</h1>
      <h4>
        Bent u op zoek naar een IT Developer? Wenst u meer informatie over de
        huidige markt te bekomen? Wij helpen u graag bij uw projecten.
      </h4>
      <p>
        Vanuit onze waarden en normen zijn we de partner bij uitstek voor heel
        wat organisaties in diverse sectoren. Wij hebben steeds een transparante
        en respectvolle langetermijnrelatie als doel.
      </p>
      <p>
        Met ons vernieuwend concept interveniëren wij op uw project, met een
        methodologie die een oplossing aanreikt voor uw nood aan korte termijn
        flexibiliteit en lange termijn stabiliteit. Hoe deze ogenschijnlijke
        paradox werkt, leggen we graag uit in een persoonlijk gesprek.
      </p>
      <p>
        Hier stopt het natuurlijk niet. Wij zijn gekend voor onze doorgedreven
        opvolging en opleiding. Wij streven telkens naar een win-winsituatie. U
        tevreden, wij tevreden!
      </p>
    </div>
  );
}
