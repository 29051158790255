import { NavLink } from "react-router-dom";

export function PrivacyFormInput({
  name,
  register,
  errors = {},
  placeholder = "",
  required = false,
}) {
  return (
    <div className="d-flex flex-col mb-s w-80 mx-auto">
      <div className="d-flex flex-row">
        <label
          htmlFor={name}
          className={`text-small mb-xs mx-xs ${
            errors[name] ? "text-error" : ""
          }`}
        >
          Door dit formulier te versturen, geef je RP One informatie die enkel
          gebruikt wordt om te solliciteren en contact met jou op te nemen. Meer
          informatie vind je terug in het
          <NavLink to={"/privacybeleid"} className="inline-link transition-all">
            Privacybeleid
          </NavLink>
          {required && <span className="error">*</span>}
        </label>
        <input
          type="checkbox"
          name={name}
          id={name}
          placeholder={placeholder}
          {...register(name, { required })}
          className={`border-radius-s  px-xs py-xs max-w-400 mx-xs ${
            errors[name] ? "border-error" : "border-black-10"
          }`}
        />
      </div>
      {required && errors[name] && (
        <p className="text-error text-small mx-xs">
          Je bent verplicht akkoord te gaan met het privacybeleid.
        </p>
      )}
    </div>
  );
}
