import { useState } from "react";
import { useForm } from "react-hook-form";
import { Button } from "../atoms/Button";
import { FormInput } from "../atoms/FormInput";
import { FormInputLinkedIn } from "../atoms/FormInputLinkedIn";
import { FormTextarea } from "../atoms/FormTextarea";
import { FormInputFile } from "../atoms/FormInputFile";
import useWindowDimensions from "../functions/WindowDimensions";
import AnimateHeight from "react-animate-height";
import ReCAPTCHA from "react-google-recaptcha";
import React from "react";
import { baseUrl, reCAPTCHAKey } from "../data";
import { PrivacyFormInput } from "../atoms/PrivacyFormInput";

export function UploadForm() {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const { isMobile, isTablet, isDesktop } = useWindowDimensions();
  const recaptchaRef = React.createRef();

  const formBaseClass = "bg-black-05 w-100 mx-s py-s shadow";
  const formMobileClass = " d-flex flex-col justify-sb mt-m";
  const formTabletClass = " max-w-500 border-radius-l px-s";
  const formDesktopClass = " max-w-700 border-radius-l px-s";
  const formClasses =
    formBaseClass +
    (isMobile
      ? formMobileClass
      : isTablet
      ? formTabletClass
      : formDesktopClass);

  const formInputBaseClass = "d-flex flex-row flex-wrap w-100 justify-c";
  const formInputMobileClass = " ";
  const formInputTabletClass = " max-w-600 mx-auto";
  const formInputDesktopClass = "";
  const formInputClasses =
    formInputBaseClass +
    (isMobile
      ? formInputMobileClass
      : isTablet
      ? formInputTabletClass
      : formInputDesktopClass);

  const [send, setSend] = useState(false);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [cvFile, setCVFile] = useState(null);

  const onSubmit = async (data) => {
    setLoading(true);
    if (data.linkedIn !== "") {
      data.linkedIn = "https://www.linkedin.com/in/" + data.linkedIn;
    }

    let token;
    try {
      token = await recaptchaRef.current.executeAsync();
    } catch (error) {
      console.error("Login error:", error);
    }

    let formData = new FormData();
    formData.append("firstName", data.firstName);
    formData.append("lastName", data.name);
    formData.append("email", data.email);
    formData.append("phone", data.phone);
    formData.append("message", data.motivation);
    formData.append("availability", "Niet ingevuld");
    formData.append("cv", cvFile, cvFile.name);
    formData.append("linkedin", data.linkedIn);
    formData.append("job", window.location.href);

    fetch(`${baseUrl}/joboffer?token=${token}`, {
      method: "POST",
      body: formData,
    })
      .then((response) => {
        setSend(response.ok);
        setError(!response.ok);
      })
      .catch((err) => {
        console.error(err);
        setError(true);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <AnimateHeight>
      <form className={formClasses} onSubmit={handleSubmit(onSubmit)}>
        {!send && (
          <div className={formInputClasses}>
            <div className="text-small text-a-r w-100 mr-xs">
              Velden met * zijn verplicht
            </div>
            <FormInput
              label="Voornaam"
              name="firstName"
              register={register}
              required
              errors={errors}
            />
            <FormInput
              label="Naam"
              name="name"
              register={register}
              required
              errors={errors}
            />

            <FormInput
              label="Email"
              name="email"
              register={register}
              required
              errors={errors}
              type="email"
            />
            <FormInput
              label="Telefoon"
              name="phone"
              register={register}
              required
              errors={errors}
              type="tel"
            />

            <FormInputLinkedIn
              label="LinkedIn"
              name="linkedIn"
              register={register}
            />
            <FormInputFile
              label="CV"
              name="cv"
              register={register}
              errors={errors}
              required
              setFileInForm={setCVFile}
            />

            <FormTextarea
              label="Motivatie"
              name="motivation"
              register={register}
              errors={errors}
            />

            <PrivacyFormInput
              name="privacy"
              register={register}
              required
              errors={errors}
            />

            <Button type="submit" text="Verstuur" isLoading={loading} />
            <ReCAPTCHA
              ref={recaptchaRef}
              size="invisible"
              sitekey={reCAPTCHAKey}
            />
          </div>
        )}
        {send && (
          <div className="form-sent">
            <h1>Bedankt voor uw interesse</h1>
            <p>We nemen zo snel mogelijk contact met u op.</p>
          </div>
        )}
        {error && (
          <div className="text-error text-small mx-xs mt-s">
            Er is iets mis gegaan met het verzenden van je gegevens &hellip; we
            raden je aan om het later even opnieuw te proberen.
          </div>
        )}
      </form>
    </AnimateHeight>
  );
}
