import { useState } from "react";
import { useForm } from "react-hook-form";
import { Button } from "../atoms/Button";
import { FormInput } from "../atoms/FormInput";
import { FormTextarea } from "../atoms/FormTextarea";
import useWindowDimensions from "../functions/WindowDimensions";
import ReCAPTCHA from "react-google-recaptcha";
import React from "react";
import { baseUrl, reCAPTCHAKey } from "../data";
import { PrivacyFormInput } from "../atoms/PrivacyFormInput";

export function ContactForm() {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const { isMobile, isTablet, isDesktop } = useWindowDimensions();
  const recaptchaRef = React.createRef();

  const [send, setSend] = useState(false);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const onSubmit = async (data) => {
    setLoading(true);

    let token;
    try {
      token = await recaptchaRef.current.executeAsync();
    } catch (error) {
      console.error("Login error:", error);
    }

    let formData = new FormData();
    formData.append("firstName", data.firstName);
    formData.append("lastName", data.name);
    formData.append("email", data.email);
    formData.append("phone", data.phone);
    formData.append("message", data.message);

    fetch(`${baseUrl}/contact?token=${token}`, {
      method: "POST",
      body: formData,
    })
      .then((response) => {
        setSend(response.ok);
        setError(!response.ok);
      })
      .catch((err) => {
        console.error(err);
        setError(true);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const formBaseClass = "bg-black-05 w-100 mx-s py-s shadow";
  const formMobileClass = " d-flex flex-col justify-sb mt-m";
  const formTabletClass = " max-w-500 border-radius-l px-s";
  const formDesktopClass = " max-w-700 border-radius-l px-s";
  const formClasses =
    formBaseClass +
    (isMobile
      ? formMobileClass
      : isTablet
      ? formTabletClass
      : formDesktopClass);

  const formInputBaseClass = "d-flex flex-row flex-wrap w-100 justify-c";
  const formInputMobileClass = " ";
  const formInputTabletClass = " max-w-600 mx-auto";
  const formInputDesktopClass = "";
  const formInputClasses =
    formInputBaseClass +
    (isMobile
      ? formInputMobileClass
      : isTablet
      ? formInputTabletClass
      : formInputDesktopClass);

  if (!send) {
    return (
      <form className={formClasses} onSubmit={handleSubmit(onSubmit)}>
        <div className={formInputClasses}>
          <div className="text-small text-a-r w-100 mr-xs">
            Velden met * zijn verplicht
          </div>
          <FormInput
            label="Voornaam"
            name="firstName"
            register={register}
            required
            errors={errors}
            placeholder="John"
          />
          <FormInput
            label="Naam"
            name="name"
            register={register}
            required
            errors={errors}
            placeholder="Doe"
          />
          <FormInput
            label="Email"
            name="email"
            register={register}
            required
            errors={errors}
            type="email"
            placeholder="john.doe@mail.be"
          />
          <FormInput
            label="Telefoon"
            name="phone"
            register={register}
            required
            errors={errors}
            type="tel"
            placeholder="+3223100000"
          />
          <FormTextarea
            label="Bericht"
            name="message"
            register={register}
            required
            errors={errors}
            placeholder="Typ hier uw bericht ..."
          />
          <PrivacyFormInput
            name="privacy"
            register={register}
            required
            errors={errors}
          />
          <Button type="submit" text="Verstuur" isLoading={loading} />
        </div>
        <ReCAPTCHA ref={recaptchaRef} size="invisible" sitekey={reCAPTCHAKey} />
        {error && (
          <div className="text-error text-small mx-xs mt-s">
            Er is iets mis gegaan met het verzenden van je gegevens &hellip; we
            raden je aan om het later even opnieuw te proberen.
          </div>
        )}
      </form>
    );
  }
  if (send) {
    return (
      <div className="form-sent">
        <h1>Bedankt voor uw bericht</h1>
        <p>We nemen zo snel mogelijk contact met u op.</p>
      </div>
    );
  }
}
