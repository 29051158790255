import { Grid2x2 } from "../components/Grid2x2";
import { Hero } from "../components/Hero";
import { JobSection } from "../components/JobSection";
import { Page } from "../components/Page";
import { solicitationProcedure } from "../data";
import useWindowDimensions from "../functions/WindowDimensions";

export function JobsRoute() {
  const { isMobile } = useWindowDimensions();

  const titleBaseClass =
    "text-white text-shadow-primary mb-m max-w-800 bg-blur";
  const titleClasses = titleBaseClass + (isMobile ? " text-a-c" : " text-a-l");

  return (
    <Page>
      <Hero title={"Jobs"}>
        <h4 className={titleClasses}>
          Zin om te werken bij bedrijven waarbij je projecten omzet in een
          technisch succes? Om deze uitdagingen aan te gaan zijn we continu op
          zoek naar nieuwe talenten.
        </h4>
      </Hero>
      <JobSection title={"Jobs"} />
      <Grid2x2
        title={"Hoe verloopt de sollicitatieprocedure?"}
        elements={solicitationProcedure}
      />
    </Page>
  );
}
