export function Divider({ size }) {
  return (
    <div
      style={{
        width: size,
        height: "5px",
        backgroundColor: "var(--color-accent)",
        borderRadius: "5px",
        marginBottom: "16px",
        transition: "all 0.5s",
      }}
    />
  );
}
