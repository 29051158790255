import { ContactItems } from "../atoms/ContactItems";
import { NavigationItems } from "../atoms/NavigationItems";
import useWindowDimensions from "../functions/WindowDimensions";
import { ReactComponent as Logo } from "../images/logo-white.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLinkedin } from "@fortawesome/free-brands-svg-icons";
import { FooterNavigationItems } from "../atoms/FooterNavItems";
import { Link } from "react-router-dom";

export function Footer(props) {
  const { isMobile, isTablet, isDesktop } = useWindowDimensions();

  const footerBaseClasses = "text-white px-m py-m";
  const footerMobileClasses = " d-flex flex-col justify-c align-c";
  const footerTabletClasses = " d-grid grid-cols-2 gap-m justify-c";
  const footerDesktopClasses = " d-flex flex-row gap-m max-w-1200 mx-auto";
  const footerClasses =
    footerBaseClasses +
    (isMobile
      ? footerMobileClasses
      : isTablet
      ? footerTabletClasses
      : footerDesktopClasses);

  const footerTitleBaseClasses = "border-b-accent text-white text-bold";
  const footerTitleMobileClasses = " text-a-c py-s";
  const footerTitleTabletClasses = " text-a-c pb-s";
  const footerTitleDesktopClasses = " text-a-c";
  const footerTitleClasses =
    footerTitleBaseClasses +
    (isMobile
      ? footerTitleMobileClasses
      : isTablet
      ? footerTitleTabletClasses
      : footerTitleDesktopClasses);

  const logoBaseClasses = "transition-all hover-accent-children";
  const logoMobileClasses = " mx-auto";
  const logoTabletClasses = " text-a-c mx-auto";
  const logoDesktopClasses = "";
  const logoClasses =
    logoBaseClasses +
    (isMobile
      ? logoMobileClasses
      : isTablet
      ? logoTabletClasses
      : logoDesktopClasses);

  return (
    <footer className="bg-primary">
      <div className={footerClasses}>
        <div className={logoClasses}>
          <Link to={"/"}>
            <Logo />
          </Link>
        </div>
        <div className="w-100">
          <h3 className={footerTitleClasses}>Socials</h3>
          <div className="d-flex justify-c align-c">
            <a
              href="https://www.linkedin.com/company/rp-one"
              target={"_blank"}
              rel="noreferrer"
              className="text-white py-xs h1 transition-all"
            >
              <FontAwesomeIcon icon={faLinkedin} />
            </a>
          </div>
        </div>
        <div className="w-100">
          <h3 className={footerTitleClasses}>Menu</h3>
          <FooterNavigationItems
            classes={" d-flex flex-col align-c justify-c w-max mx-auto"}
          />
        </div>
        <div className="w-100">
          <h3 className={footerTitleClasses}>Contact</h3>
          <ContactItems
            classes={"d-flex flex-col justify-c align-c w-max mx-auto"}
          />
        </div>
      </div>
    </footer>
  );
}
