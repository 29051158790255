import { Hamburger } from "../atoms/Hamburger";
import { NavigationItems } from "../atoms/NavigationItems";
import { UploadLink } from "../atoms/UploadLink";
import "animate.css";

import { useState } from "react";

export function NavigationMobile() {
  const [open, setOpen] = useState(false);
  const handleClick = async () => {
    setOpen(!open);
  };
  return (
    <div className="navbar mx-s">
      <Hamburger onclick={() => handleClick()} open={open} />
      <NavigationItems
        classes={`nav-mobile z-5 vw-100 align-c pos-a bg-primary pb-s d-flex flex-col animate__animated ${
          open ? "animate__fadeInDown" : "animate__fadeOutUpBig animate__slow"
        }`}
      >
        <UploadLink className="border-b-accent border-s-2 w-80 mx-auto" />
      </NavigationItems>
    </div>
  );
}
