import { Divider } from "./Divider";

export function Card({ title, text, id = null }) {
  return (
    <div className="bg-white shadow border-radius-m px-s py-s mb-s max-w-350 d-block-inline">
      <div>
        <h4 className="mb-xs">
          {id ? <span className="text-secondary h1">{id}. &nbsp;</span> : null}
          {title}
        </h4>
        <Divider size="100px" />
        <p>{text}</p>
      </div>
    </div>
  );
}
