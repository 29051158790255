import { useState, useEffect } from "react";

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

export default function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return {
    windowDimensions: windowDimensions,
    isMobile: windowDimensions.width < 768,
    isTablet: windowDimensions.width >= 768 && windowDimensions.width < 1024,
    isDesktop: windowDimensions.width >= 1024,
  };
}
