import { Hero } from "../components/Hero";
import { Page } from "../components/Page";
import useWindowDimensions from "../functions/WindowDimensions";

export function PrivacyBeleidRoute() {
  const { isMobile, isTablet, isDesktop } = useWindowDimensions();

  const sectionBaseClass = "";
  const sectionMobileClass = " d-flex flex-col justify-c align-c";
  const sectionTabletClass =
    " d-flex flex-row justify-sb my-m mx-s gap-s max-w-1000";
  const sectionDesktopClass =
    " d-flex flex-row justify-sb my-m mx-auto gap-s max-w-1200";
  const sectionClasses =
    sectionBaseClass +
    (isMobile
      ? sectionMobileClass
      : isTablet
      ? sectionTabletClass
      : sectionDesktopClass);

  const articleClass = "pl-m pr-m";
  const listClass = "standard-ul";

  return (
    <Page>
      <Hero title={"Privacybeleid"}></Hero>
      <section className={sectionClasses}>
        <div className={articleClass}>
          <p>
            Dit privacybeleid zet uiteen hoe RP One alle informatie gebruikt en
            beschermt die u RP One geeft wanneer u deze website gebruikt.
          </p>
          <p>
            RP One doet er alles aan om ervoor te zorgen dat uw privacy wordt
            beschermd. Mochten we u vragen om bepaalde informatie te verstrekken
            waarmee u bij het gebruik van deze website kunt worden
            ge&iuml;dentificeerd, dan kunt u er zeker van zijn dat deze alleen
            zal worden gebruikt in overeenstemming met deze privacyverklaring.
          </p>
          <p>
            RP One kan dit beleid van tijd tot tijd wijzigen door deze pagina
            bij te werken. U dient deze pagina van tijd tot tijd te controleren
            om er zeker van te zijn dat u tevreden bent met eventuele
            wijzigingen.
          </p>
          <h1>Wat we verzamelen</h1>
          <p>We kunnen de volgende informatie verzamelen:</p>
          <ul className={listClass}>
            <li>Naam en functie</li>
            <li>Contactgegevens inclusief e-mailadres</li>
            <li>
              Demografische informatie zoals postcode, voorkeuren en interesses
            </li>
            <li>
              Overige informatie die relevant is voor klantonderzoeken en / of
              aanbiedingen
            </li>
          </ul>
          <h1>Wat we doen met de informatie die we verzamelen</h1>
          <p>
            We hebben deze informatie nodig om uw behoeften te begrijpen en u
            beter van dienst te kunnen zijn, en in het bijzonder om de volgende
            redenen:
          </p>
          <ul className={listClass}>
            <li>Intern bijhouden van gegevens.</li>
            <li>
              We kunnen de informatie gebruiken om onze producten en diensten te
              verbeteren.
            </li>
            <li>
              We kunnen periodiek promotionele e-mails sturen over nieuwe
              producten, speciale aanbiedingen of andere informatie waarvan wij
              denken dat u deze interessant vindt met behulp van het e-mailadres
              dat u heeft opgegeven.
            </li>
            <li>
              Van tijd tot tijd kunnen we uw gegevens ook gebruiken om contact
              met u op te nemen voor marktonderzoeksdoeleinden. We kunnen
              contact met u opnemen via e-mail, telefoon, fax of post. We kunnen
              de informatie gebruiken om de website aan te passen aan uw
              interesses.
            </li>
          </ul>
          <h1>Veiligheid</h1>
          <p>
            We doen er alles aan om ervoor te zorgen dat uw informatie veilig
            is. Om ongeautoriseerde toegang of openbaarmaking te voorkomen,
            hebben we geschikte fysieke, elektronische en bestuurlijke
            procedures ingevoerd om de informatie die we online verzamelen te
            beschermen en te beveiligen.
          </p>
          <h1>Hoe we cookies gebruiken</h1>
          <p>
            Een cookie is een klein bestand dat toestemming vraagt om op de
            harde schijf van uw computer te worden geplaatst. Zodra u akkoord
            gaat, wordt het bestand toegevoegd en helpt de cookie het webverkeer
            te analyseren of laat het u weten wanneer u een bepaalde site
            bezoekt. Cookies stellen webtoepassingen in staat om op u als
            individu te reageren. De webapplicatie kan zijn activiteiten
            afstemmen op uw behoeften, voorkeuren en antipathie&euml;n door
            informatie over uw voorkeuren te verzamelen en te onthouden.
          </p>
          <p>
            We gebruiken verkeerslogcookies om te identificeren welke pagina's
            worden gebruikt. Dit helpt ons gegevens over webpaginaverkeer te
            analyseren en onze website te verbeteren om deze af te stemmen op de
            behoeften van de klant. We gebruiken deze informatie alleen voor
            statistische analysedoeleinden en vervolgens worden de gegevens uit
            het systeem verwijderd.
          </p>
          <p>
            Over het algemeen helpen cookies ons om u een betere website te
            bieden, doordat we kunnen bijhouden welke pagina's u nuttig vindt en
            welke niet. Een cookie geeft ons op geen enkele manier toegang tot
            uw computer of enige informatie over u, behalve de gegevens die u
            met ons wilt delen.
          </p>
          <p>
            U kunt ervoor kiezen om cookies te accepteren of te weigeren. De
            meeste webbrowsers accepteren cookies automatisch, maar u kunt uw
            browserinstellingen meestal wijzigen om cookies te weigeren als u
            dat wilt. Hierdoor kunt u mogelijk niet optimaal profiteren van de
            website.
          </p>
          <h1>Links naar andere websites</h1>
          <p>
            Onze website kan links naar andere interessante websites bevatten.
            Als u deze links eenmaal hebt gebruikt om onze site te verlaten,
            dient u er rekening mee te houden dat wij geen enkele controle
            hebben over die andere website. Daarom kunnen wij niet
            verantwoordelijk zijn voor de bescherming en privacy van alle
            informatie die u verstrekt tijdens uw bezoek aan dergelijke sites en
            dergelijke sites vallen niet onder deze privacyverklaring. Wees
            voorzichtig en bekijk de privacyverklaring die van toepassing is op
            de website in kwestie.
          </p>
          <h1>Beheer van uw persoonlijke gegevens</h1>
          <p>
            U kunt ervoor kiezen om de verzameling of het gebruik van uw
            persoonlijke gegevens op de volgende manieren te beperken:
          </p>
          <ul className={listClass}>
            <li>
              Wanneer u wordt gevraagd om een formulier op de website in te
              vullen, zoek dan naar het vakje waarop u kunt klikken om aan te
              geven dat u niet wilt dat de informatie door iemand wordt gebruikt
              voor directmarketingdoeleinden.
            </li>
            <li>
              Als u eerder heeft ingestemd met het gebruik van uw persoonlijke
              gegevens voor directmarketingdoeleinden, kunt u op elk moment van
              gedachten veranderen door te schrijven of te e-mailen naar{" "}
              <a href="mailto:info@rp-one.eu">info@rp-one.eu</a>
            </li>
          </ul>
          <p>
            We zullen uw persoonlijke gegevens niet verkopen, verspreiden of
            verhuren aan derden, tenzij we uw toestemming hebben of wettelijk
            verplicht zijn om dit te doen. We kunnen uw persoonlijke gegevens
            gebruiken om u promotionele informatie over derden te sturen waarvan
            wij denken dat u deze interessant vindt, als u ons vertelt dat u dit
            wenst.
          </p>
          <p>
            U kunt details opvragen van persoonlijke informatie die we over u
            hebben onder de Data Protection Act 1998. Een kleine vergoeding zal
            worden betaald. Als u een kopie wenst van de informatie die over u
            wordt bewaard, kunt u schrijven naar RP One, Telecom Gardens,
            Medialaan 36, 1800 Vilvoorde, Belgi&euml;.
          </p>
          <p>
            Als u van mening bent dat enige informatie die we over u hebben
            onjuist of onvolledig is, schrijf of e-mail ons dan zo snel mogelijk
            op het bovenstaande adres. We zullen alle informatie die onjuist
            blijkt te zijn, onmiddellijk corrigeren.
          </p>
          <h1>Google Analytics</h1>
          <p>
            We zullen uw persoonlijke gegevens niet verkopen, verspreiden of
            verhuren aan derden, tenzij we uw toestemming hebben of wettelijk
            verplicht zijn om dit te doen. We kunnen uw persoonlijke gegevens
            gebruiken om u promotionele informatie over derden te sturen waarvan
            wij denken dat u deze interessant vindt, als u ons vertelt dat u dit
            wenst.
          </p>
          <p>
            U kunt details opvragen van persoonlijke informatie die we over u
            hebben onder de Data Protection Act 1998. Een kleine vergoeding zal
            worden betaald. Indien u een kopie wenst van de informatie die over
            u wordt bewaard, schrijf dan naar RP One, Telecom Gardens, Medialaan
            36, 1800 Vilvoorde, Belgi&euml;.
          </p>
          <p>
            Als u van mening bent dat enige informatie die we over u hebben
            onjuist of onvolledig is, schrijf of e-mail ons dan zo snel mogelijk
            op het bovenstaande adres. We zullen alle informatie die onjuist
            blijkt te zijn, onmiddellijk corrigeren.
          </p>
        </div>
      </section>
    </Page>
  );
}
