import { Hero } from "../components/Hero";
import { Page } from "../components/Page";
import useWindowDimensions from "../functions/WindowDimensions";

export function AlgemeneVoorwaardenRoute() {
  const { isMobile, isTablet, isDesktop } = useWindowDimensions();

  const sectionBaseClass = "";
  const sectionMobileClass = " d-flex flex-col justify-c align-c";
  const sectionTabletClass =
    " d-flex flex-row justify-sb my-m mx-s gap-s max-w-1000";
  const sectionDesktopClass =
    " d-flex flex-row justify-sb my-m mx-auto gap-s max-w-1200";
  const sectionClasses =
    sectionBaseClass +
    (isMobile
      ? sectionMobileClass
      : isTablet
      ? sectionTabletClass
      : sectionDesktopClass);

  const articleClass = "pl-m pr-m";
  const listClass = "standard-ol";

  return (
    <Page>
      <Hero title={"Algemene voorwaarden"}></Hero>
      <section className={sectionClasses}>
        <div className={articleClass}>
          <p>
            Welkom op onze website. Als u doorgaat met bladeren en deze website
            gebruiken, gaat u ermee akkoord zich te houden aan en gebonden te
            zijn aan de volgende gebruiksvoorwaarden, die samen met ons
            privacybeleid de relatie van RP One met u met betrekking tot deze
            website regelen. Gebruik onze website niet als u het niet eens bent
            met een deel van deze algemene voorwaarden.
          </p>
          <p>
            De term ‘RP One’ of ‘ons’ of ‘wij’ verwijst naar de eigenaar van de
            website met maatschappelijke zetel in Telecom Gardens, Medialaan 36,
            1800 Vilvoorde, Belgi&euml;. De term ‘jij’ verwijst naar de
            gebruiker of kijker van onze website.
          </p>
          <p>
            Het gebruik van deze website is onderworpen aan de volgende
            gebruiksvoorwaarden:
          </p>
          <ol className={listClass}>
            <li>
              De inhoud van de pagina's van deze website is alleen voor uw
              algemene informatie en gebruik. Het kan zonder voorafgaande
              kennisgeving worden gewijzigd.
            </li>
            <li>
              Deze website maakt gebruik van cookies om browsevoorkeuren te
              volgen. Als u het gebruik van cookies toestaat, kan de volgende
              persoonlijke informatie door ons worden opgeslagen voor gebruik
              door derden.
            </li>
            <li>
              Noch wij, noch derden bieden enige garantie of garantie met
              betrekking tot de nauwkeurigheid, tijdigheid, prestatie,
              volledigheid of geschiktheid van de informatie en materialen die
              op deze website worden gevonden of aangeboden voor een bepaald
              doel. U erkent dat dergelijke informatie en materialen
              onnauwkeurigheden of fouten kunnen bevatten en wij sluiten
              uitdrukkelijk aansprakelijkheid uit voor dergelijke
              onnauwkeurigheden of fouten voor zover toegestaan door de wet.
            </li>
            <li>
              Uw gebruik van enige informatie of materiaal op deze website is
              geheel op eigen risico, waarvoor wij niet aansprakelijk zijn. Het
              is uw eigen verantwoordelijkheid om ervoor te zorgen dat alle
              producten, diensten of informatie die via deze website beschikbaar
              zijn, voldoen aan uw specifieke vereisten.
            </li>
            <li>
              Deze website bevat materiaal dat eigendom is van of in licentie is
              gegeven aan ons. Dit materiaal omvat, maar is niet beperkt tot,
              het ontwerp, de lay-out, het uiterlijk, het uiterlijk en de
              afbeeldingen. Reproductie is verboden anders dan in
              overeenstemming met de copyright-mededeling, die deel uitmaakt van
              deze algemene voorwaarden.
            </li>
            <li>
              Alle handelsmerken die op deze website worden gereproduceerd en
              die geen eigendom zijn van of in licentie zijn gegeven aan de
              exploitant, worden erkend op de website.
            </li>
            <li>
              Ongeautoriseerd gebruik van deze website kan aanleiding geven tot
              schadevergoeding en/of een strafbaar feit zijn.
            </li>
            <li>
              Deze website kan van tijd tot tijd ook links naar andere websites
              bevatten. Deze links zijn bedoeld voor uw gemak om meer informatie
              te verstrekken. Ze betekenen niet dat we de website(s)
              onderschrijven. Wij hebben geen verantwoordelijkheid voor de
              inhoud van de gelinkte website(s).
            </li>
            <li>
              Uw gebruik van deze website en elk geschil dat voortvloeit uit
              dergelijk gebruik van de website is onderworpen aan de wetten van
              Belgi&euml;.
            </li>
          </ol>
        </div>
      </section>
    </Page>
  );
}
