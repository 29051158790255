import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import useWindowDimensions from "../functions/WindowDimensions";

export function Switch({
  options = [{ title: "no option title", icon: faXmark, id: 1 }],
  state,
  setState,
}) {
  const { isMobile, isTablet, isDesktop } = useWindowDimensions();

  const handleClick = (id) => {
    if (id === state) return;
    setState(id);
  };

  return (
    <div className="bg-primary border-radius-full vw-80 d-flex d-row justify-se px-xs py-xs gap-m max-w-700 mx-auto mh-l mt-s">
      {options.map((option) => {
        return (
          <div
            key={option.id}
            onClick={() => handleClick(option.id)}
            className={`d-flex switch-btn h4 align-c flex-row justify-c w-100 h-100 border-radius-full transition-all px-s py-s text-bold text-white ${
              option.id === state
                ? "bg-accent text-white"
                : "hover-secondary hover-secondary-children"
            }`}
          >
            {typeof option.icon === "string" ? (
              option.icon
            ) : (
              <FontAwesomeIcon
                className={`h4 text-white transition-all ${
                  isMobile ? "mx-auto" : "mr-s"
                }`}
                icon={option.icon}
              />
            )}
            {!isMobile && typeof option.icon !== "string" && (
              <span>{option.title}</span>
            )}
          </div>
        );
      })}
    </div>
  );
}
