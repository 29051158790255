import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "./Button";
import { faUpload } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";

export function FormInputFile({
  name,
  label = name,
  register,
  errors = {},
  placeholder = "",
  required = false,
  setFileInForm,
}) {
  const [file, setFile] = useState(null);
  const fileName = file ? file.name : "";

  return (
    <div className="d-flex flex-col mb-s w-100 mx-auto align-c">
      <input
        type={"file"}
        name={name}
        id={name}
        placeholder={placeholder}
        {...register(name, { required })}
        className="d-none"
        onChange={(e) => {
          setFile(e.target.files[0]);
          setFileInForm(e.target.files[0]);
        }}
      />
      <label
        htmlFor={name}
        className="btn px-m py-s shadow border-radius-m min-w-200 h4 text-bold text-white bg-accent border-none transition-all text-a-c"
      >
        <FontAwesomeIcon icon={faUpload} className="mr-s" />
        Upload {label} {required && <span className="error">*</span>}
      </label>
      {fileName && (
        <p className="border-black-10 border-radius-m bg-white px-s py-xs">
          {" "}
          {fileName}
        </p>
      )}
      {required && errors[name] && (
        <p className="text-error text-small mx-xs">{label} is verplicht</p>
      )}
    </div>
  );
}
