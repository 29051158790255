export function ButtonCta({ text, onClick }) {
  return (
    <div className="text-a-c">
      <button
        className={`btn btn-cta px-m py-s shadow border-radius-full h4 text-bold text-white bg-accent border-none min-w-300 transition-all pos-r text-uppercase`}
        onClick={onClick}
      >
        <span className="z-1 pos-r">{text}</span>
      </button>
    </div>
  );
}
