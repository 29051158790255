import useWindowDimensions from "../functions/WindowDimensions";

export function FormInputLinkedIn({
  name,
  label = name,
  register,
  errors = {},
  type = "text",
  required = false,
}) {
  return (
    <div className={`d-flex flex-col mb-s w-100 mx-auto align-c`}>
      <label
        htmlFor={name}
        className={`h4 mb-xs mx-xs ${errors[name] ? "text-error" : ""}`}
      >
        {label} {required && <span className="error">*</span>}
      </label>
      <div className={"w-100 d-flex flex-row flex-wrap justify-c"}>
        <input
          disabled
          value="http://www.linkedin.com/in/"
          className={`border-radius-s px-xs py-xs max-w-400 ${
            errors[name] ? "border-error" : "border-black-10"
          }`}
        />
        <input
          type={type}
          name={name}
          id={name}
          {...register(name, { required })}
          className={`border-radius-s  px-xs py-xs max-w-400 ${
            errors[name] ? "border-error" : "border-black-10"
          }`}
        />
      </div>
      {required && errors[name] && (
        <p className="error error-message">{label} is verplicht</p>
      )}
    </div>
  );
}
