import { Card } from "../atoms/Card";
import { Divider } from "../atoms/Divider";
import useWindowDimensions from "../functions/WindowDimensions";

export function Grid2x2({ title, elements }) {
  const { isMobile, isTablet, isDesktop } = useWindowDimensions();

  const articleBaseClass = "";
  const articleMobileClass = "d-flex flex-col gap-m";
  const articleTabletClass = "d-grid grid-cols-2 gap-m";
  const articleDesktopClass = "d-grid grid-cols-2 gap-m max-w-1200 mx-auto";
  const articleClasses =
    articleBaseClass +
    (isMobile
      ? articleMobileClass
      : isTablet
      ? articleTabletClass
      : articleDesktopClass);

  return (
    <section className="bg-black-05 px-m py-m">
      <div className="mb-m max-w-1000 mx-auto">
        <h2>{title}</h2>
        <Divider size="250px" />
      </div>
      <article className={articleClasses}>
        {elements.map((element, index) => (
          <Card
            title={element.title}
            text={element.text}
            key={element.id ? element.id : index}
            id={element.id}
          />
        ))}
      </article>
    </section>
  );
}
