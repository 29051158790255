import { ContactItems } from "../atoms/ContactItems";
import { ContactForm } from "../components/ContactForm";
import { Hero } from "../components/Hero";
import { Page } from "../components/Page";
import useWindowDimensions from "../functions/WindowDimensions";

export function ContactRoute() {
  const { isMobile, isTablet, isDesktop } = useWindowDimensions();

  const sectionBaseClass = "";
  const sectionMobileClass = " d-flex flex-col justify-c align-c";
  const sectionTabletClass =
    " d-flex flex-row justify-sb my-m mx-s gap-s max-w-1000";
  const sectionDesktopClass =
    " d-flex flex-row justify-sb my-m mx-auto gap-s max-w-1200";
  const sectionClasses =
    sectionBaseClass +
    (isMobile
      ? sectionMobileClass
      : isTablet
      ? sectionTabletClass
      : sectionDesktopClass);

  const contactBaseClass = " bg-primary border-radius-l px-s py-s mx-s shadow";
  const contactMobileClass = "";
  const contactTabletClass = " min-w-250 max-w-250 max-h-300";
  const contactDesktopClass = " min-w-250 max-w-250 max-h-300";
  const contactClasses =
    contactBaseClass +
    (isMobile
      ? contactMobileClass
      : isTablet
      ? contactTabletClass
      : contactDesktopClass);

  return (
    <Page>
      <Hero title={"Contact"}></Hero>
      <section className={sectionClasses}>
        <article className={contactClasses}>
          <h1 className="text-white border-b-accent border-s-2">Contact</h1>
          <ContactItems />
        </article>
        <ContactForm />
      </section>
    </Page>
  );
}
