import { NavLink } from "react-router-dom";
import useWindowDimensions from "../functions/WindowDimensions";

export function Legal(props) {
  const { isMobile, isTablet, isDesktop } = useWindowDimensions();

  const baseClasses =
    "d-flex bg-primary-dark text-white justify-sb align-c text-a-c";
  const mobileClasses = " flex-col justify-c align-c";
  const tabletClasses = " flex-row px-s";
  const desktopClasses = " flex-row px-l";
  const classes =
    baseClasses +
    (isMobile ? mobileClasses : isTablet ? tabletClasses : desktopClasses);

  const linkBaseClasses = "d-flex";
  const linkMobileClasses = " flex-col";
  const linkTabletClasses = " flex-row gap-s";
  const linkDesktopClasses = "";
  const linkClasses =
    linkBaseClasses +
    (isMobile
      ? linkMobileClasses
      : isTablet
      ? linkTabletClasses
      : linkDesktopClasses);

  return (
    <div className={classes}>
      <div>&copy; RP One - 2023</div>
      <div className={linkClasses}>
        <NavLink
          to={"/privacybeleid"}
          className="text-white transition-all py-xs mx-s"
        >
          Privacybeleid
        </NavLink>
        <NavLink
          to={"/algemene-voorwaarden"}
          className="text-white transition-all py-xs mx-s"
        >
          Algemene voorwaarden
        </NavLink>
        <NavLink
          to={"/disclaimer"}
          className="text-white transition-all py-xs mx-s"
        >
          Disclaimer
        </NavLink>
      </div>
    </div>
  );
}
