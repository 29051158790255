import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faXmark } from "@fortawesome/free-solid-svg-icons";

export function Hamburger(props) {
  const { onclick, open } = props;
  return (
    <div className="hamburger text-accent h3 ml-auto" onClick={onclick}>
      <FontAwesomeIcon icon={open ? faXmark : faBars} />
    </div>
  );
}
