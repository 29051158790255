import { Switch } from "../atoms/Switch";
import useWindowDimensions from "../functions/WindowDimensions";
import { jobs } from "../data";
import { useState } from "react";
import { Divider } from "../atoms/Divider";
import { JobCard } from "../atoms/JobCard";
import { CSSTransition, SwitchTransition } from "react-transition-group";
import AnimateHeight from "react-animate-height";

export function JobSection({ title }) {
  const [activeID, setActiveID] = useState(jobs[0].id);
  const activeJob = jobs.find((job) => job.id === activeID);

  const cardHeight = 100 + 32 + 16;
  const titleHeight = 43 + 64;
  const height = titleHeight + cardHeight * activeJob.openings.length;

  const { isMobile, isTablet } = useWindowDimensions();

  const titleDividerBaseClass = "my-m";
  const titleDividerMobileClass = " max-w-300 mx-auto";
  const titleDividerTabletClass = " max-w-600 mx-auto";
  const titleDividerDesktopClass = " max-w-800 mx-auto";
  const titleDividerClasses =
    titleDividerBaseClass +
    (isMobile
      ? titleDividerMobileClass
      : isTablet
      ? titleDividerTabletClass
      : titleDividerDesktopClass);

  const articleBaseClass = "";
  const articleTabletClass = " d-grid grid-cols-2 gap-m max-w-700 mx-auto";
  const articleDesktopClass = "d-grid grid-cols-3 gap-m max-w-1050 mx-auto";
  const articleClasses =
    articleBaseClass + (isTablet ? articleTabletClass : articleDesktopClass);

  return (
    <section className="p-small transition-all">
      {isMobile && (
        <>
          <Switch options={jobs} state={activeID} setState={setActiveID} />
          <AnimateHeight height={height}>
            <SwitchTransition>
              <CSSTransition
                key={activeID}
                transition={"fade"}
                timeout={200}
                in={activeID === activeJob.id}
                classNames="fade"
              >
                <article>
                  <div className={titleDividerClasses}>
                    <h2>{activeJob.title}</h2>
                    <Divider size="250px" />
                  </div>
                  <div className="d-flex flex-col justify-c align-c px-m">
                    {activeJob.openings.map((opening) => (
                      <JobCard
                        {...opening}
                        key={opening.id}
                        parent={activeJob.id}
                      />
                    ))}
                  </div>
                </article>
              </CSSTransition>
            </SwitchTransition>
          </AnimateHeight>
        </>
      )}
      {!isMobile && (
        <article className={articleClasses}>
          {jobs.map((job) => (
            <div key={job.id}>
              <div className={titleDividerClasses}>
                <h2>{job.title}</h2>
                <Divider size="250px" />
              </div>
              <div className="flex-col">
                {job.openings.map((opening) => (
                  <JobCard {...opening} key={opening.id} parent={job.id} />
                ))}
              </div>
            </div>
          ))}
        </article>
      )}
    </section>
  );
}
