import { useNavigate } from "react-router-dom";
import { Button } from "../atoms/Button";

export function VoorWerknemers() {
  const navigate = useNavigate();

  function navigateToJobs() {
    navigate("/jobs");
  }

  return (
    <div>
      <h1>Voor werknemers</h1>
      <h4>
        Wij helpen jou om je carrière te lanceren of er een nieuwe wending aan
        te geven!
      </h4>
      <p>
        Ben jij pas afgestudeerd? Of ben je op zoek naar een nieuwe wending in
        je carrière? Dan ben je bij ons aan het juiste adres! RP One helpt jou
        om je carrière te (her)lanceren. Met onze jarenlange ervaring en
        specialisatie in IT development en onze gaan we meteen aan de slag voor
        je. We luisteren naar wat jouw ervaringen, talenten en wensen zijn om
        vervolgens een passend project bij een van onze partners te vinden.
      </p>
      <p>
        Ben je klaar voor een nieuw avontuur? Neem dan snel een kijkje in onze
        openstaande jobs.
      </p>
      <Button onClick={navigateToJobs} text={"Naar onze openstaande jobs"} />
    </div>
  );
}
