import { Navigation } from "../components/Navigation";
import { Footer } from "../components/Footer";
import { Legal } from "../atoms/Legal";
import { CookieBanner } from "../components/CookieBanner";

export function Page(props) {
  return (
    <div className="App">
      <Navigation />
      {props.children}
      <Footer />
      <Legal />
      <CookieBanner />
    </div>
  );
}
