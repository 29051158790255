import useWindowDimensions from "../functions/WindowDimensions";

export function Hero(props) {
  const { title, children } = props;
  const { isMobile, isTablet, isDesktop } = useWindowDimensions();

  const baseClasses = "hero-image text-white d-flex justify-c";
  const desktopClasses = " align-c justify-s mx-auto w-90";
  const heroImageClasses = baseClasses + (isDesktop ? desktopClasses : "");

  const titleBaseClass = "title text-white text-shadow-primary mb-m";
  const titleClasses = titleBaseClass + (isMobile ? " text-a-c" : " text-a-l");

  const contentClass =
    "pt-l px-s w-max mw-800 " + (isDesktop ? "ml-l pt-0" : "");

  return (
    <div className={`hero bg-gradient`}>
      <div className={heroImageClasses}>
        <div className={contentClass}>
          <h1 className={titleClasses}>{title}</h1>
          {children}
        </div>
      </div>
    </div>
  );
}
