import useWindowDimensions from "../functions/WindowDimensions";

export function Section({ image, children }) {
  const { isMobile, isTablet, isDesktop } = useWindowDimensions();

  const sectionBaseClasses = "d-flex";
  const sectionMobileClasses = " flex-col-r";
  const sectionTabletClasses = " flex-col align-c";
  const sectionDesktopClasses = " flex-row align-c max-w-1000 mx-auto";
  const sectionClasses =
    sectionBaseClasses +
    (isMobile
      ? sectionMobileClasses
      : isTablet
      ? sectionTabletClasses
      : sectionDesktopClasses);

  const imageBaseClasses = " w-100 h-100";
  const imageMobileClasses = " w-100 h-100";
  const imageTabletClasses = " w-100 h-100";
  const imageDesktopClasses = "";
  const imageClasses =
    imageBaseClasses +
    (isMobile
      ? imageMobileClasses
      : isTablet
      ? imageTabletClasses
      : imageDesktopClasses);

  const imageWrapperBaseClasses = "d-flex justify-c align-c overflow-hidden";
  const imageWrapperMobileClasses = " max-h-250";
  const imageWrapperTabletClasses =
    " max-h-500 min-h-500 max-w-500 min-w-500 border-radius-l my-m";
  const imageWrapperDesktopClasses =
    " max-h-500 min-h-500 max-w-500 min-w-500 border-radius-l mx-m my-m";
  const imageWrapperClasses =
    imageWrapperBaseClasses +
    (isMobile
      ? imageWrapperMobileClasses
      : isTablet
      ? imageWrapperTabletClasses
      : imageWrapperDesktopClasses);

  const contentBaseClasses = "my-s";
  const contentMobileClasses = " mx-s";
  const contentTabletClasses =
    " sectionContent pos-r ml-xl min-w-500 max-w-500 min-h-500 bg-black-05 px-m py-m border-radius-xl d-flex flex-col justify-sb shadow";
  const contentDesktopClasses = "";
  const contentClasses =
    contentBaseClasses +
    (isMobile
      ? contentMobileClasses
      : isTablet
      ? contentTabletClasses
      : contentDesktopClasses);

  return (
    <section className={sectionClasses}>
      <div className={imageWrapperClasses}>
        <img src={image} className={imageClasses} alt="" />
      </div>
      <article className={contentClasses}>{children}</article>
    </section>
  );
}
