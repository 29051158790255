import { ReactComponent as Logo } from "../images/logo.svg";
import { NavigationMobile } from "./NavigationMobile";
import { NavigationTablet } from "./NavigationTablet";
import { NavigationDesktop } from "./NavigationDesktop";
import useWindowDimensions from "../functions/WindowDimensions";
import { Link } from "react-router-dom";

export function Navigation(props) {
  const { isMobile, isTablet, isDesktop } = useWindowDimensions();

  return (
    <nav className={"navigation bg-primary px-s py-s d-flex justify-sb"}>
      <div className="brand">
        <Link to={"/"}>
          <Logo />
        </Link>
      </div>
      {isMobile && <NavigationMobile />}
      {isTablet && <NavigationTablet />}
      {isDesktop && <NavigationDesktop />}
    </nav>
  );
}
