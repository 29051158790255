import { useState } from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { Switch } from "../atoms/Switch";
import { Hero } from "../components/Hero";
import { Section } from "../components/Section";
import { hoeWijWerken } from "../data";
import { Page } from "../components/Page";

export function HoeWijWerkenRoute() {
  const [activeID, setActiveID] = useState(hoeWijWerken[0].id);
  const activeSection = hoeWijWerken.find((section) => section.id === activeID);

  return (
    <Page>
      <Hero title={"Hoe wij werken ..."}></Hero>
      <Switch options={hoeWijWerken} state={activeID} setState={setActiveID} />
      <TransitionGroup component={null}>
        <CSSTransition
          key={activeID}
          transition={"fade"}
          timeout={500}
          in={activeID === activeSection.id}
          classNames="fade"
        >
          <Section image={activeSection.image}>{activeSection.content}</Section>
        </CSSTransition>
      </TransitionGroup>
    </Page>
  );
}
