import "./styles/Reset.css";
import "./styles/App.css";
import "./styles/Navigation.css";

import "reactjs-popup/dist/index.css";

import { BrowserRouter, Route, Routes } from "react-router-dom";
import { HomeRoute } from "./routes/Home";
import { OverOnsRoute } from "./routes/OverOns";
import { JobsRoute } from "./routes/Jobs";
import { HoeWijWerkenRoute } from "./routes/HoeWijWerken";
import { ContactRoute } from "./routes/Contact";
import { UploadCVRoute } from "./routes/UploadCV";
import { JobsDetailsRoute } from "./routes/JobsDetails";
import { PrivacyBeleidRoute } from "./routes/PrivacyBeleid";
import { AlgemeneVoorwaardenRoute } from "./routes/AlgemeneVoorwaarden";
import { DisclaimerRoute } from "./routes/Disclaimer";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<HomeRoute />} />
        <Route path="/over-ons" element={<OverOnsRoute />} />
        <Route path="/jobs" element={<JobsRoute />} />
        <Route path="/upload-cv" element={<UploadCVRoute />} />
        <Route path="/contact" element={<ContactRoute />} />
        <Route path="/hoe-wij-werken" element={<HoeWijWerkenRoute />} />
        <Route path="/jobs/:jobID/:openingID" element={<JobsDetailsRoute />} />
        <Route path="/privacybeleid" element={<PrivacyBeleidRoute />} />
        <Route
          path="/algemene-voorwaarden"
          element={<AlgemeneVoorwaardenRoute />}
        />
        <Route path="/disclaimer" element={<DisclaimerRoute />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
