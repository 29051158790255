import { Hero } from "../components/Hero";
import { Page } from "../components/Page";
import useWindowDimensions from "../functions/WindowDimensions";

export function DisclaimerRoute() {
  const { isMobile, isTablet, isDesktop } = useWindowDimensions();

  const sectionBaseClass = "";
  const sectionMobileClass = " d-flex flex-col justify-c align-c";
  const sectionTabletClass =
    " d-flex flex-row justify-sb my-m mx-s gap-s max-w-1000";
  const sectionDesktopClass =
    " d-flex flex-row justify-sb my-m mx-auto gap-s max-w-1200";
  const sectionClasses =
    sectionBaseClass +
    (isMobile
      ? sectionMobileClass
      : isTablet
      ? sectionTabletClass
      : sectionDesktopClass);

  const articleClass = "pl-m pr-m";

  return (
    <Page>
      <Hero title={"Disclaimer"}></Hero>
      <section className={sectionClasses}>
        <div className={articleClass}>
          <p>
            De informatie op deze website is alleen bedoeld voor algemene
            informatiedoeleinden. De informatie wordt verstrekt door RP One en
            hoewel we ernaar streven de informatie up-to-date en correct te
            houden, geven we geen verklaringen of garanties van welke aard dan
            ook, expliciet of impliciet, over de volledigheid, nauwkeurigheid,
            betrouwbaarheid, geschiktheid of beschikbaarheid met betrekking tot
            de website of de informatie, producten, diensten of gerelateerde
            afbeeldingen op de website voor welk doel dan ook. Elk vertrouwen
            dat u in dergelijke informatie stelt, is daarom strikt op eigen
            risico.
          </p>
          <p>
            In geen geval zijn wij aansprakelijk voor enig verlies of schade,
            inclusief maar niet beperkt tot indirecte of gevolgschade of schade,
            of enig verlies of welke schade dan ook die voortvloeit uit verlies
            van gegevens of winsten die voortvloeien uit of verband houden met
            het gebruik van deze website.
          </p>
          <p>
            Via deze website kunt u doorlinken naar andere websites die niet
            onder controle staan van RP One. We hebben geen controle over de
            aard, inhoud en beschikbaarheid van die sites. Het opnemen van links
            impliceert niet noodzakelijk een aanbeveling of onderschrijft de
            standpunten die erin worden geuit.
          </p>
          <p>
            Er wordt alles aan gedaan om de website soepel te laten werken. RP
            One neemt echter geen verantwoordelijkheid voor en zal niet
            aansprakelijk zijn voor het tijdelijk niet beschikbaar zijn van de
            website vanwege technische problemen buiten onze controle.
          </p>
        </div>
      </section>
    </Page>
  );
}
