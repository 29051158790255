import { NavLink } from "react-router-dom";
import { Button } from "../atoms/Button";
import { useState } from "react";

export function CookieBanner() {
  const acceptCookie = () => {
    localStorage.setItem("_gdpr_accepted", true);
    setCookieAccepted(true);
  };

  const [cookieAccepted, setCookieAccepted] = useState(
    localStorage.getItem("_gdpr_accepted") !== null
  );

  const cookieClasses = "cookie pb-xs pt-xs pr-m pl-m";
  const linkClass = "inline-link";

  return (
    !cookieAccepted && (
      <div className={cookieClasses}>
        <div>
          Deze website gebruikt cookies om je een betere ervaring te bieden
          terwijl je deze website bezoekt. Lees het
          <NavLink to={"/privacybeleid"}>
            <div className={linkClass}>privacybeleid</div>
          </NavLink>
          .
        </div>
        <Button text={"Akkoord"} onClick={acceptCookie}></Button>
      </div>
    )
  );
}
