import { Grid2x2 } from "../components/Grid2x2";
import { Hero } from "../components/Hero";
import { Page } from "../components/Page";
import { Section } from "../components/Section";
import { companyValues } from "../data";
import image from "../images/over-ons.jpg";

export function OverOnsRoute() {
  return (
    <Page>
      <Hero title={"Over ons"} />
      <Section image={image}>
        <p>
          RP One is een IT consultancy bedrijf. We helpen onze partners met de
          realisatie, succes en groei van hun IT-projecten. Dit doen we door
          tussenkomst van onze talentvolle consultants. Onze focus ligt vooral
          op het leveren van kwaliteit in het hele proces, van het eerste
          contact met de consultant tot de follow-up en het verdere verloop van
          de carrière.
        </p>
        <h1 className="my-s">Waarvoor wij staan</h1>
        <h4>
          Consultant en partner worden bijgestaan in het hele proces. We gaan
          voor duurzaam ondernemerschap, waarbij de juiste persoon op de juiste
          plaats terechtkomt. Dit doen we met de grootste zorg en marktkennis.
        </h4>
      </Section>
      <Grid2x2
        title="Waarden en cultuur van het bedrijf"
        elements={companyValues}
      />
    </Page>
  );
}
