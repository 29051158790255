import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUpFromBracket } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

export function UploadLink({ className }) {
  return (
    <Link
      to={"/upload-cv"}
      className={`upload-link text-accent h3 text-a-c px-s py-s d-flex justify-c align-c ${className}`}
    >
      <FontAwesomeIcon
        icon={faArrowUpFromBracket}
        className="transition-all mr-xs"
      />
      <span className="transition-all">Upload jouw CV</span>
    </Link>
  );
}
