import useWindowDimensions from "../functions/WindowDimensions";

export function FormTextarea({
  name,
  label = name,
  register,
  errors = {},
  placeholder = "",
  type = "text",
  required = false,
}) {
  return (
    <div className={`d-flex flex-col mb-s w-100 mx-auto align-c`}>
      <label
        htmlFor={name}
        className={`h4 mb-xs mx-xs w-80 ${errors[name] ? "text-error" : ""}`}
      >
        {label} {required && <span className="error">*</span>}
      </label>
      <textarea
        type={type}
        name={name}
        id={name}
        placeholder={placeholder}
        rows="6"
        {...register(name, { required })}
        className={`border-radius-s border-primary px-xs py-xs w-80 mx-xs ${
          errors[name] ? "border-error" : "border-black-10"
        }`}
      />
      {required && errors[name] && (
        <p className="text-error text-small mx-xs w-80 max-w-600">
          {label} is verplicht
        </p>
      )}
    </div>
  );
}
