import { Hero } from "../components/Hero";
import { Page } from "../components/Page";
import { UploadForm } from "../components/UploadForm";
import useWindowDimensions from "../functions/WindowDimensions";

export function UploadCVRoute() {
  const { isMobile, isTablet, isDesktop } = useWindowDimensions();

  const sectionBaseClass = "";
  const sectionMobileClass = " d-flex flex-col justify-c align-c";
  const sectionTabletClass =
    " d-flex flex-row justify-sb my-m mx-s gap-s max-w-1000";
  const sectionDesktopClass =
    " d-flex flex-row justify-sb my-m mx-auto gap-s max-w-1200";
  const sectionClasses =
    sectionBaseClass +
    (isMobile
      ? sectionMobileClass
      : isTablet
      ? sectionTabletClass
      : sectionDesktopClass);

  return (
    <Page>
      <Hero title="Upload" />
      <section className={sectionClasses}>
        <article className={`contact mx-s`}>
          <h1>Geïnteresseerd?</h1>
          <p>
            Laat hier zeker jouw CV en contactgegevens achter en wij zullen
            binnenkort contact met jou opnemen. Zo kunnen we samen jou carrière
            lanceren!
          </p>
        </article>
        <UploadForm />
      </section>
    </Page>
  );
}
