import { NavLink } from "react-router-dom";
import useWindowDimensions from "../functions/WindowDimensions";

export function NavigationItems({ classes, children }) {
  const { isMobile, isTablet, isDesktop } = useWindowDimensions();

  const baseClasses = "navbar-item text-white text-a-c";
  const mobileClasses = " py-s border-b-accent border-s-2 w-80";
  const tabletClasses = " py-s border-b-accent border-s-2 w-80";
  const desktopClasses = " mx-s";

  const navListClasses =
    baseClasses +
    (isMobile ? mobileClasses : isTablet ? tabletClasses : desktopClasses);

  return (
    <ul className={`navbar-items ${classes}`}>
      {children}
      <li className={navListClasses}>
        <NavLink to={"/over-ons"} className="text-white h4 w-100">
          Over ons
        </NavLink>
      </li>
      <li className={navListClasses}>
        <NavLink to={"/jobs"} className="text-white h4 w-100">
          Jobs
        </NavLink>
      </li>
      <li className={navListClasses}>
        <NavLink to={"/hoe-wij-werken"} className="text-white h4 w-100">
          Hoe wij werken
        </NavLink>
      </li>
      <li className={navListClasses}>
        <NavLink to={"/contact"} className="text-white h4 w-100">
          Contact
        </NavLink>
      </li>
    </ul>
  );
}
